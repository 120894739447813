import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  isMenuOpen$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  getThemes(): any {
    return [
      {
        value: 'default',
        name: 'Light',
      },
      {
        value: 'material-light',
        name: 'Material Light',
      },

    ];
  }
}

import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService, NullValidationHandler } from 'angular-oauth2-oidc';

import { filter } from 'rxjs/operators';
import { AuthUser } from '../interfaces/auth-user.interface';
import { Subject } from 'rxjs';

export enum EventType0Auth {
  discovery_document_loaded = 'discovery_document_loaded',
  jwks_load_error = 'jwks_load_error',
  invalid_nonce_in_state = 'invalid_nonce_in_state',
  discovery_document_load_error = 'discovery_document_load_error',
  discovery_document_validation_error = 'discovery_document_validation_error',
  user_profile_loaded = 'user_profile_loaded',
  user_profile_load_error = 'user_profile_load_error',
  token_received = 'token_received',
  token_error = 'token_error',
  code_error = 'code_error',
  token_refreshed = 'token_refreshed',
  token_refresh_error = 'token_refresh_error',
  silent_refresh_error = 'silent_refresh_error',
  silently_refreshed = 'silently_refreshed',
  silent_refresh_timeout = 'silent_refresh_timeout',
  token_validation_error = 'token_validation_error',
  token_expires = 'token_expires',
  session_changed = 'session_changed',
  session_error = 'session_error',
  session_terminated = 'session_terminated',
  session_unchanged = 'session_unchanged',
  logout = 'logout',
  popup_closed = 'popup_closed',
  popup_blocked = 'popup_blocked',
  token_revoke_error = 'token_revoke_error',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  onRefreshToken$: Subject<void> = new Subject();
  private user: AuthUser;

  get userProfile() {
    return this.user;
  }

  isLogged = false;

  constructor(private oauthService: OAuthService, private authConfig: AuthConfig) {}

  async initAuth(): Promise<any> {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();

    this.oauthService.events
      .pipe(
        filter(({ type }) =>
          [
            EventType0Auth.token_received,
            EventType0Auth.token_expires,
            EventType0Auth.token_refreshed,
            EventType0Auth.silently_refreshed,
            EventType0Auth.silent_refresh_timeout,
            EventType0Auth.invalid_nonce_in_state,
          ].includes(type as EventType0Auth),
        ),
      )
      .subscribe(({ type }) => {
        console.warn('Event type oauth ---- ', type);
        if ((type as EventType0Auth) === EventType0Auth.token_received) {
          this.onRefreshToken$.next();
        }
      });

    return new Promise<void>((resolveFn, rejectFn) => {
      this.oauthService.loadDiscoveryDocumentAndLogin().then(
        (isLoggedIn) => {
          if (isLoggedIn) {
            this.isLogged = isLoggedIn;
            this.oauthService.setupAutomaticSilentRefresh();
            this.setUser();

            resolveFn();
          } else {
            this.oauthService.initImplicitFlow();
            rejectFn();
          }
        },
        (error) => {
          console.error('Error in oauthService.loadDiscoveryDocumentAndLogin', { error });
          if (error.status === 400) {
            location.reload();
          }
        },
      );
    });
  }


  isLoggedIn() {
    return this.isLogged;
  }

  loadUser(): Promise<AuthUser> {
    return this.oauthService.loadUserProfile().then((user: { info: AuthUser }) => Promise.resolve(user.info));
  }

  getToken() {
    return this.oauthService.getAccessToken();
  }

  setUser() {
    this.oauthService.loadUserProfile().then((user: any) => {
      this.user = user.info;
    });
  }

  logout() {
    this.isLogged = false;
    this.oauthService.logOut();
  }
}

// import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";
import { Directive, Output, EventEmitter, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[smcClickOutside]',
})
export class ClickOutsideDirective implements OnInit {
  @Output() appClickOutside = new EventEmitter<void>();

  ready = false;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    setTimeout(() => {
      this.ready = true;
    });
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && this.ready) {
      this.appClickOutside.emit();
    }
  }
}

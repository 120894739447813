import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    }

    console.error('Not authenticated, redirecting and adding redirect url...');
    return false;
  }

  /**
   * Handle roles
   * // Force the user to log in if currently unauthenticated.
    // if (!this.authenticated) {
    //   await this.authService.login();
    // }

    // this.authService.loadUserProfile();

    // const allowedRoles = route.data.allowedRoles;

    // if (!(allowedRoles instanceof Array) || allowedRoles.length === 0) {
    //   return true;
    // }

    // if (this.roles?.find((role) => includes(allowedRoles, role))) {
    //   return true;
    // } else {
    //   return false;
    // }
   *
   */
}

import { Observable } from 'rxjs';
import { Storage, SESSION_STORAGE_PREFIX } from './state-storage.models';

const sessionStorageKeys = (): string[] => {
  const keys = [];
  for (let i = 0; i < sessionStorage.length; i++) {
    keys.push(sessionStorage.key(i));
  }
  return keys;
};

export const clearAllSessionStorage = (): void => {
  sessionStorageKeys().forEach((key) => {
    if (key.startsWith(SESSION_STORAGE_PREFIX)) {
      sessionStorage.removeItem(key);
    }
  });
};

export const removeSessionStorageItem = (removeKey: string): void => {
  const fullKey = `${SESSION_STORAGE_PREFIX}_${removeKey}`;
  if (sessionStorageKeys().some((key) => key === fullKey)) {
    sessionStorage.removeItem(fullKey);
  }
};

export class SmcSessionStorage implements Storage {
  private get fullKey() {
    return `${SESSION_STORAGE_PREFIX}_${this.key}`;
  }

  constructor(public key: string) {}

  read<T>(): Observable<T> | T {
    const val = sessionStorage.getItem(this.fullKey);
    if (!val) {
      return val as any;
    }
    return JSON.parse(val);
  }

  write<T>(state: T) {
    try {
      if (state == null) {
        sessionStorage.removeItem(this.fullKey);
      } else {
        sessionStorage.setItem(this.fullKey, JSON.stringify(state));
      }
    } catch (e) {
      console.error('SmcSessionStorage can only accept serializable value');
    }
  }
}

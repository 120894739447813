import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const STATE_STORAGE = new InjectionToken<StateStorage<any>>('SMC_STATE_STORAGE_TOKEN');
export const SESSION_STORAGE_PREFIX = 'SMC_STATE_STORAGE';

export interface StateStorage<T> {
  stateStorageChanged$: Observable<T>;
  initStateStorage(value: T);
}

export interface Storage {
  write<T>(state: T);
  read<T>(): Observable<T> | T;
}

import { Directive, HostListener, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[smcEscClose]',
})
export class EscapeCloseDirective {
  @Input() preventEscClose = false;
  @Output() closeDialog = new Subject();

  constructor() {}

  @HostListener('window:keyup.esc') onKeyUp() {
    if (this.preventEscClose) {
      return;
    }

    (document.activeElement as HTMLElement).blur();
    this.closeDialog.next();
  }
}

import {
  RouterModule,
  Routes,
} from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './pages/shared/components/miscellaneous/not-found/not-found.component';
import { AuthGuard } from './pages/shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: false,
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

<div class="header-container">
    <div [class]="'logo-container ' + isSidebarState">
        <nb-action class="logo" (click)="navigateTo('/')">
            <nb-icon icon="logo" pack="icons"></nb-icon>
        </nb-action>
        <a (click)="toggleSidebar()" class="sidebar-toggle ml-2">
            <nb-icon
                *ngIf="isSidebarExpanded; else compacted"
                icon="sidebarArrowLeft"
                pack="icons"
            ></nb-icon>
            <ng-template #compacted>
                <nb-icon icon="sidebarArrowRight" pack="icons"></nb-icon>
            </ng-template>
        </a>
    </div>
</div>

<div class="header-container">
    <nb-actions size="small">
        <nb-action
            [class]="'create-button sm-const-button ' + notificationButtonState"
            (click)="navigateTo('/dashboard/notifications')"
        >
            <nb-icon icon="bell" pack="icons"></nb-icon>
        </nb-action>
        <nb-action class="create-button logout">
            <div
                *ngIf="user | async as user"
                (click)="openMenu()"
                class="d-flex flex-row align-items-center"
            >
                <span>{{ user.name }}</span>
                <nb-icon
                    class="ml-2"
                    *ngIf="!this.isMenuOpen; else up"
                    icon="chevron-down-outline"
                ></nb-icon>
                <ng-template #up>
                    <nb-icon class="ml-2" icon="chevron-up-outline"> </nb-icon
                ></ng-template>
            </div>
            <div
                *ngIf="isMenuOpen$ | async"
                class="d-flex flex-column"
                (smcClickOutside)="closeMenu()"
                (appClickOutside)="closeMenu()"
            >
                <nb-card class="profile-menu" #profileMenu>
                    <button mat-raised-button (click)="navigateTo('profile')">
                        {{ 'PROFILE' | customTranslate }}
                    </button>

                    <mat-divider></mat-divider>

                    <button mat-raised-button (click)="logout()">
                        {{ 'LOGOUT' | customTranslate }}
                    </button>
                </nb-card>
            </div>
        </nb-action>
    </nb-actions>
</div>

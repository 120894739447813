import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbSidebarService, NbSidebarState, NbThemeService } from '@nebular/theme';

import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { RippleService } from '../../../@core/utils/ripple.service';
import { HeaderService } from './header.service';
import { clearAllSessionStorage } from '../../../pages/shared/directives/state-storage/session-storage';
import { AuthService } from '../../../pages/services/auth.service';
import { AuthUser } from '../../../pages/interfaces/auth-user.interface';

@Component({
  selector: 'smc-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  themes = this.headerService.getThemes();
  currentTheme = 'material-light';
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  btnStatus = '';
  isMenuOpen$ = this.headerService.isMenuOpen$;
  isMenuOpen: boolean;
  isSidebarExpanded: boolean = window.innerWidth > 1200;
  isSidebarState: NbSidebarState = 'expanded';
  user: Promise<AuthUser>;

  get notificationButtonState(): string {
    return this.router.routerState.snapshot.url.includes('notifications') ? 'active' : 'passive';
  }

  public constructor(
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private rippleService: RippleService,
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private authService: AuthService,
  ) {
    this.isSidebarState = this.isSidebarExpanded ? 'expanded' : 'compacted';
    this.themeService.changeTheme(this.currentTheme);
    this.sidebarService
      .onToggle()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.isSidebarState = this.isSidebarExpanded ? 'expanded' : 'compacted'));

    this.materialTheme$ = this.themeService.onThemeChange().pipe(
      map((theme) => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }),
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSidebarExpanded = event.target.innerWidth > 1200;
    this.isSidebarState = this.isSidebarExpanded ? 'expanded' : 'compacted';
  }

  async ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.user = this.authService.loadUser();
    console.log('this.user', this.user);
    this.btnStatus = this.router.routerState.snapshot.url.includes('notifications') ? 'active' : 'passive';

    const { xl } = this.breakpointService.getBreakpointsMap();

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
      )
      .subscribe((url) => {
        this.btnStatus = url.includes('notifications') ? 'active' : 'passive';
      });

    this.route.url
      .pipe(
        takeUntil(this.destroy$),
        map((segments) => segments.join('')),
      )
      .subscribe((url) => {
        this.btnStatus = url.includes('notifications') ? 'active' : 'passive';
      });

    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
      });

    this.isMenuOpen$.subscribe((open) => (this.isMenuOpen = open));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.isSidebarExpanded = !this.isSidebarExpanded;
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.headerService.isMenuOpen$.next(false);
    return false;
  }

  logout() {
    clearAllSessionStorage();
    this.authService.logout();
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
    return false;
  }

  openMenu() {
    this.headerService.isMenuOpen$.next(!this.isMenuOpen);
  }

  closeMenu() {
    this.headerService.isMenuOpen$.next(false);
  }
}
